import { mapGetters } from 'vuex';
import { customMapActions } from '@/vue/stores/Store';

import UserValidationsModule from '@/stores/UserValidations/UserValidationsModule';

export const {
  // Getters
  areRequiredDocumentsForCustomers,
  areRequiredDocumentsForCorporates,
  isValidStatusByType,
  userValidationsByBookingTypeOperator,
  hasValidationStatus,
  requiredDocuments,
  isRequiredDocumentsLoading,
  hasUploadedDrivingLicense,
  hasCompletedDrivingLicense,
  hasCompletedBadge,

  // Actions
  getUserCityValidations,
  fetchUserValidations,
  fetchRequiredDocuments,
} = {
  ...mapGetters(UserValidationsModule.NAMESPACE, {
    isValidStatusByType: UserValidationsModule.GETTERS.isValidStatusByType,
    userValidationsByBookingTypeOperator: UserValidationsModule.GETTERS.userValidationsByBookingTypeOperator,
    hasValidationStatus: UserValidationsModule.GETTERS.hasValidationStatus,
    requiredDocuments: UserValidationsModule.GETTERS.requiredDocuments,
    isRequiredDocumentsLoading: UserValidationsModule.GETTERS.isRequiredDocumentsLoading,
    hasUploadedDrivingLicense: UserValidationsModule.GETTERS.hasUploadedDrivingLicense,
    hasCompletedDrivingLicense: UserValidationsModule.GETTERS.hasCompletedDrivingLicense,
    hasCompletedBadge: UserValidationsModule.GETTERS.hasCompletedBadge,
    areRequiredDocumentsForCustomers: UserValidationsModule.GETTERS.areRequiredDocumentsForCustomers,
    areRequiredDocumentsForCorporates: UserValidationsModule.GETTERS.areRequiredDocumentsForCorporates,
  }),

  ...customMapActions(UserValidationsModule.NAMESPACE, {
    fetchRequiredDocuments: UserValidationsModule.ACTIONS.fetchRequiredDocuments,
    fetchUserValidations: UserValidationsModule.ACTIONS.fetchUserValidations,
    getUserCityValidations: UserValidationsModule.ACTIONS.getUserCityValidations,
  }),
};
