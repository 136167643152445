import { mapActions, mapGetters } from 'vuex';

import * as favouriteLocationsModule from './FavouriteLocationsModule';

export const {
  fetchFavouriteLocations,
  postFavouriteLocation,
  deleteFavouriteLocation,
  currentFavouriteLocations,
} = {
  ...mapActions(favouriteLocationsModule.NAMESPACE, [
    favouriteLocationsModule.ACTIONS.fetchFavouriteLocations,
    favouriteLocationsModule.ACTIONS.postFavouriteLocation,
    favouriteLocationsModule.ACTIONS.deleteFavouriteLocation,
  ]),
  ...mapGetters(favouriteLocationsModule.NAMESPACE, [favouriteLocationsModule.GETTERS.currentFavouriteLocations]),
};
