import { mapGetters } from 'vuex';
import { GETTERS, nameSpace as bookingTypeNameSpace } from '@/vue/stores/BookingType/BookingTypeStore';
export const {
  getCurrentBookingType,
  getBookingTypeCollection,
} = {
  ...mapGetters(bookingTypeNameSpace, [
    GETTERS.getCurrentBookingType,
    GETTERS.getBookingTypeCollection,
  ]),
};
