import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import cfg from '@/config';

const defaultFlavor = 'ecod';

const getSource = src => (startsWith(src, '/') ? src : `/${src}`);

const getCurrentFlavor = () => (get(cfg, 'data.flavor') || defaultFlavor);

const ImportDynamicAssets = {

  icon: src => {
    const source = getSource(src);
    const currentFlavor = getCurrentFlavor();

    try {
      // eslint-disable-next-line
      return require(`@/assets/images/icons/${currentFlavor}${source}`);
    } catch (e) {
      // eslint-disable-next-line
      return require(`@/assets/images/icons/${defaultFlavor}${source}`);
    }
  },

  json: src => {
    const source = getSource(src);
    const currentFlavor = getCurrentFlavor();

    try {
      // eslint-disable-next-line
      return require(`@/assets/json/${currentFlavor}${source}`);
    } catch (e) {
      // eslint-disable-next-line
      return require(`@/assets/json/${defaultFlavor}${source}`);
    }
  },
};

export default ImportDynamicAssets;
