import { mapGetters } from 'vuex';

import store from '@/vue/stores/Store';
import * as OperatorModule from '@/vue/stores/CSOperator/CSOperatorStore';

export const { getOperator, findCSOperatorByUUID, getVisitedCsOperator } = {
  getOperator: (...params) => store.dispatch(
    `${OperatorModule.nameSpace}/${OperatorModule.ACTIONS.fetchCSOperator}`,
    ...params,
  ),

  ...mapGetters(OperatorModule.nameSpace, {
    findCSOperatorByUUID: OperatorModule.GETTERS.findCSOperatorByUUID,
    getVisitedCsOperator: OperatorModule.GETTERS.getVisitedCsOperator,
  }),
};
