import cfg from '@/config';
import * as ecodResources from '@/assets/images/icons/ecod/marker/parkings';
import * as santanderResources from '@/assets/images/icons/santander/marker/parkings';

export class MapMarkerSelector {
  constructor() {
    this.operator = cfg.data.flavor;
  }

  setOperator(operator) {
    this.operator = operator;
  }

  getOperator() {
    return this.operator;
  }

  svgCollection() {
    switch (this.operator) {
      case cfg.brands.santander:
        return santanderResources;
      default:
        return ecodResources;
    }
  }

  static color(c) {
    return c;
  }

  disabledOneWay(color, vehicleCount) {
    return this.svgCollection().disabledOneWay(color, vehicleCount);
  }

  disabledHoverOneWay(color, vehicleCount) {
    return this.svgCollection().disabledHoverOneWay(color, vehicleCount);
  }

  oneWay() {
    return this.svgCollection().oneWay();
  }

  oneDigitOneWay(color, vehicleCount) {
    return this.svgCollection().oneDigitOneWay(color, vehicleCount);
  }

  oneDigitHoverOneWay(color, vehicleCount) {
    return this.svgCollection().oneDigitHoverOneWay(color, vehicleCount);
  }

  twoDigitsOneWay(color, vehicleCount) {
    return this.svgCollection().twoDigitsOneWay(color, vehicleCount);
  }

  twoDigitsHoverOneWay(color, vehicleCount) {
    return this.svgCollection().twoDigitsHoverOneWay(color, vehicleCount);
  }

  brand() {
    return this.svgCollection().brand();
  }

  brandHover() {
    return this.svgCollection().brandHover();
  }

  parkingIcon() {
    return this.svgCollection().parking();
  }

  pickupSelected() {
    return this.svgCollection().pickupSelected();
  }

  dropOff() {
    return this.svgCollection().dropOff();
  }

  dropOffSelected() {
    return this.svgCollection().dropOffSelected();
  }

  parkingOneWayIcon() {
    return this.svgCollection().parkingOneWay();
  }

  dropOffBusiness() {
    return this.svgCollection().dropOffBusiness();
  }

  disabledBrand(color) {
    return this.svgCollection().disabledBrand(color);
  }

  disabledHoverBrand(color, vehicleCount) {
    return this.svgCollection().disabledHoverBrand(color, vehicleCount);
  }

  oneDigitBrand(color, vehicleCount) {
    return this.svgCollection().oneDigitBrand(color, vehicleCount);
  }

  oneDigitHoverBrand(color, vehicleCount) {
    return this.svgCollection().oneDigitHoverBrand(color, vehicleCount);
  }

  twoDigitsBrand(color, vehicleCount) {
    return this.svgCollection().twoDigitsBrand(color, vehicleCount);
  }

  twoDigitsHoverBrand(color, vehicleCount) {
    return this.svgCollection().twoDigitsHoverBrand(color, vehicleCount);
  }

  disabledBusiness(color, vehicleCount) {
    return this.svgCollection().disabledBusiness(color, vehicleCount);
  }

  disabledHoverBusiness(color, vehicleCount) {
    return this.svgCollection().disabledHoverBusiness(color, vehicleCount);
  }

  oneDigitBusiness(color, vehicleCount) {
    return this.svgCollection().oneDigitBusiness(color, vehicleCount);
  }

  oneDigitHoverBusiness(color, vehicleCount) {
    return this.svgCollection().oneDigitHoverBusiness(color, vehicleCount);
  }

  twoDigitsBusiness(color, vehicleCount) {
    return this.svgCollection().twoDigitsBusiness(color, vehicleCount);
  }

  twoDigitsHoverBusiness(color, vehicleCount) {
    return this.svgCollection().twoDigitsHoverBusiness(color, vehicleCount);
  }

  cluster() {
    return this.svgCollection().cluster();
  }

  cityMarkerIcon() {
    return this.svgCollection().cityMarker();
  }

  iconBooking() {
    return this.svgCollection().iconBooking();
  }

  iconHoverBooking() {
    return this.svgCollection().iconHoverBooking();
  }

  iconUser() {
    return this.svgCollection().userMarker();
  }

  iconPointer() {
    return this.svgCollection().pointerMarker();
  }

  iconTripStart() {
    return this.svgCollection().iconTripStart();
  }

  iconTripEnd() {
    return this.svgCollection().iconTripEnd();
  }

  geoLocationOn() {
    return this.svgCollection().geoLocationOn();
  }

  geoLocationOff() {
    return this.svgCollection().geoLocationOff();
  }

  heartFavouriteLocation() {
    return this.svgCollection().heartFavouriteLocation();
  }

  homeFavouriteLocation() {
    return this.svgCollection().homeFavouriteLocation();
  }

  workFavouriteLocation() {
    return this.svgCollection().workFavouriteLocation();
  }

  iconBusinessBrand() {
    return this.svgCollection().businessBrand();
  }
}

export const instance = new MapMarkerSelector();
