import { mapActions, mapGetters } from 'vuex';
import { ACTIONS, GETTERS, NAMESPACE } from './LegalDocumentsModule';

export const {
  missingLegalDocuments,
  getMissingLegalDocuments,
  acceptLegalDocuments,
} = {
  ...mapGetters(NAMESPACE, {
    missingLegalDocuments: GETTERS.missingLegalDocuments,
  }),

  ...mapActions(NAMESPACE, {
    getMissingLegalDocuments: ACTIONS.getMissingLegalDocuments,
    acceptLegalDocuments: ACTIONS.putUserLegalDocuments,
  }),
};
