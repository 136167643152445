import { mapGetters } from 'vuex';
import { nameSpace as datetimeNameSpace } from '@/vue/stores/DateTime/DateTimeStore';
export const {
  getStartDateTime,
  getEndDateTime,
  getDateTime,
} = {
  ...mapGetters(datetimeNameSpace, [
    'getStartDateTime',
    'getEndDateTime',
    'getDateTime',
  ]),
};
