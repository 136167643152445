import { mapActions, mapGetters } from 'vuex';
import { NAMESPACE as LogbookNamespace } from './LogbookModule';

export const {
  logbooks,
  bookingTrips,
  availaibleReasons,
  bookingWayPoints,
  fetchLogbooks,
  fetchLogbooksTrips,
  mergeLogbookTrips,
  saveLogbookTrips,
} = {
  ...mapGetters(LogbookNamespace, [
    'logbooks',
    'bookingTrips',
    'availaibleReasons',
    'bookingWayPoints',
  ]),

  ...mapActions(LogbookNamespace, [
    'fetchLogbooks',
    'fetchLogbooksTrips',
    'mergeLogbookTrips',
    'saveLogbookTrips',
  ]),
};
