<template>
  <div class="MapViewLayout LayoutPage d-flex">
    <div
      id="map-view-vehicles"
      class="MapViewLayout_left MapViewLayout_left-topSticky"
    >
      <header
        v-if="hasLeftTop"
        id="map-view-filters"
        class="MapViewLayout_left_top"
      >
        <slot name="left-top">
          <div class="skeleton w-100 h-100" />
        </slot>
      </header>
      <section class="VehicleList">
        <slot name="left-content">
          <div class="skeleton w-100 h-100" />
        </slot>
      </section>
    </div>
    <div class="MapViewLayout_right">
      <div class="MapViewLayout_right_content">
        <slot name="right-content">
          <div class="skeleton w-100 h-100" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasLeftTop() {
      return !!this.$slots['left-top'];
    },
  },
};
</script>
